import ApiService from "@/api";
import { SPACE } from "../mutation-types";
import { cloneDeep } from "lodash";

const getDefaultState = () => {
  return {
    creator: {
      resourceId: "",
      id: 0,
      name: "",
      aliasName: "",
      initial: "",
      avatar: "",
      isConnected: false,
      connected: 0,
      isCreator: false,
      category: "",
      categoryLabel: "",
      schedules: [],
      navigations: [],
      userProfile: {
        headline: "",
        displayName: "",
        isVerified: false,
        description: "",
        descriptionHtml: "",
        coverImage: "",
        coverImageUrl: "",
        mCoverImage: "",
        mCoverImageUrl: "",
        logoImage: "",
        logoImageUrl: "",
        mLogoImage: "",
        mLgoImageUrl: "",
        mLogoImageUrl: "",
      },
      tags: [],
      isLiveOn: false,
      channels: [],
    },
    space: {
      theme: "",
      background: "",
      // note 미사용시 제거
      // coverImage: null,
      // logoImage: null,
      headerType: 1,
      description: null,
      footerCompany: null,
      information: {
        title: "",
        description: "",
        button: "",
        url: "",
      },
      navigations: [],
      coverImageUrl: "",
      mCoverImageUrl: "",
      isSecret: false,
      password: null,
    },
    spaceWidgets: [],
    spaceWidgetsType: {},
    spaceChannels: [],
  };
};

const state = getDefaultState();

const getters = {
  space: (state) => {
    return state.space;
  },
  creator: (state) => {
    return state.creator;
  },
  spaceWidgets: (state) => {
    return state.spaceWidgets;
  },
  spaceWidgetsType: (state) => {
    return state.spaceWidgetsType;
  },
  spaceChannels: (state) => {
    return state.spaceChannels;
  },
};

const actions = {
  async getSpace(context) {
    try {
      const { data } = await ApiService.getSpace();
      await context.commit(SPACE.SET_SPACE, data.data);
    } catch (e) {
      console.log(e);
    }
  },
  async getSpaceWidgets({ commit }) {
    await ApiService.getSpaceWidgets().then((res) => {
      if (res.data.success) {
        commit(SPACE.SET_SPACE_WIDGETS, res.data.data);
      }
    });
  },
  async getSpaceWidgetType({ commit }) {
    await ApiService.getSpaceWidgetType().then((res) => {
      if (res.data.success) {
        commit(SPACE.SET_SPACE_TYPE, res.data.data);
      }
    });
  },
  getSpaceWidget(context, payload) {
    ApiService.getSpaceWidget(payload.spaceWidgetResourceId).then((res) => {
      console.log("getSpaceWidget");
      console.log(res);
    });
  },
  // todo 에러 처리 로직 수정
  async postSpaceWidget(context, payload) {
    await ApiService.postSpaceWidget(payload.data).then(async (res) => {
      if (res.data.success) {
        await context.dispatch("getSpace");
        await context.dispatch("getSpaceWidgetType");
      }
    });
  },
  // todo 브랜드 개편용 api, 기존 브랜드홈 제거시 해당 코드 네이밍 수정할 것
  async postSpaceWidgetV2(context, payload) {
    await ApiService.postSpaceWidget(payload.data).then(async (res) => {
      if (res.data.success) {
        // await context.dispatch("getSpaceWidget", {
        //   spaceWidgetResourceId: res.data.data.resourceId,
        // });
        await context.dispatch("getSpaceWidgets");
        await context.dispatch("getSpaceWidgetType");
      }
    });
  },
  async putSpaceWidget({ dispatch }, payload) {
    await ApiService.putSpaceWidget(
      payload.widgetResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getSpace");
      }
    });
  },
  // todo 브랜드 개편용 api, 기존 브랜드홈 제거시 해당 코드 네이밍 수정할 것
  async putSpaceWidgetV2({ dispatch }, payload) {
    await ApiService.putSpaceWidget(
      payload.widgetResourceId,
      payload.data
    ).then(async (res) => {
      if (res.data.success) {
        await dispatch("getSpaceWidgets");
        // await dispatch("getSpaceWidget", {
        //   spaceWidgetResourceId: res.data.data.resourceId,
        // });
      }
    });
  },
  async deleteSpaceWidget({ dispatch }, spaceWidgetResourceId) {
    try {
      await ApiService.deleteSpaceWidget(spaceWidgetResourceId).then(
        async (response) => {
          const result = response.data;
          if (result.success) {
            await dispatch("getSpace");
            await dispatch("getSpaceWidgetType");
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  },
  // todo 브랜드 개편용 api, 기존 브랜드홈 제거시 해당 코드 네이밍 수정할 것
  async deleteSpaceWidgetV2({ commit, dispatch }, spaceWidgetResourceId) {
    try {
      await ApiService.deleteSpaceWidget(spaceWidgetResourceId).then(
        async (response) => {
          const result = response.data;
          if (result.success) {
            // await dispatch("getSpace");
            commit(SPACE.POP_SPACE_WIDGET, {
              widgetResourceId: spaceWidgetResourceId,
            });
            await dispatch("getSpaceWidgets");
            await dispatch("getSpaceWidgetType");
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  },
  async putSpace({ dispatch }, payload) {
    await ApiService.putSpace(payload.data).then((response) => {
      const result = response.data;
      if (result.success) {
        dispatch("getSpace");
      }
    });
  },

  async getSpaceChannels({ commit }) {
    await ApiService.getSpaceChannels().then(async (res) => {
      if (res.data.success) {
        await commit(SPACE.SET_SPACE_CHANNELS, res.data.data);
      }
    });
  },

  async postSpaceChannel({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postSpaceChannel(payload).then(
        async (res) => {
          if (res.data.success) {
            await dispatch("getSpaceChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async putSpaceChannel({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.putSpaceChannel(
        payload.spaceChannelResourceId,
        payload.data
      ).then(
        async (res) => {
          if (res.data.success) {
            await dispatch("getSpaceChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async postSpaceChannelResort({ dispatch }, payload) {
    await ApiService.postSpaceChannelResort(payload).then(async (res) => {
      if (res.data.success) {
        await dispatch("getSpaceChannels");
      }
    });
  },

  async deleteSpaceChannel({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteSpaceChannel(payload.spaceChannelResourceId).then(
        async (res) => {
          if (res.data.success) {
            await dispatch("getSpaceChannels");
            resolve(res);
          } else {
            resolve(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async postSpacesCoverImageUrl({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postSpacesCoverImageUrl(payload.type, payload.data).then(
        async (response) => {
          let result = response.data;
          if (result.success) {
            // await dispatch("getSpaceChannels");
            console.log("postSpacesCoverImageUrl");
            console.log(result.data);
            resolve(result.data);
          } else {
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  async postSpacesSecret({ dispatch }, payload) {
    let result;
    try {
      result = await ApiService.postSpacesSecret(payload.data);
      await dispatch("getSpace");
      return result.data;
    } catch (e) {
      console.error(e.message);
      result = e.response.data;
      return result;
    }
  },
};

const mutations = {
  [SPACE.SET_SPACE](state, payload) {
    state.space = cloneDeep(payload.space);
    state.creator = cloneDeep(payload.creator);
    state.spaceWidgets = cloneDeep(payload.spaceWidgets);
    state.spaceChannels = cloneDeep(payload.spaceChannels);
  },
  [SPACE.SET_SPACE_TYPE](state, payload) {
    state.spaceWidgetsType = payload;
  },
  [SPACE.SET_SPACE_WIDGETS](state, payload) {
    state.spaceWidgets = cloneDeep(payload);
  },
  [SPACE.SET_SPACE_CHANNELS](state, payload) {
    state.spaceChannels = cloneDeep(payload);
  },
  [SPACE.POP_SPACE_WIDGET](state, payload) {
    let index = state.spaceWidgets.findIndex((element) => {
      return element.resourceId === payload.widgetResourceId;
    });
    state.spaceWidgets.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
