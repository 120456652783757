import Swal from "sweetalert2";
import { useToast } from "vue-toastification";

const toast = useToast();

const sweetAlertDelteCustomClass = {
  title: "sub-title-s2",
  content: "sub-text-s2 text-gray-070",
  confirmButton: "bg-purple-50",
  cancelButton: " swal2-styled--delete-alert-custom__cancle-btn",
  popup: "bg-gray-01",
  input: "bg-gray-01",
  inputLabel: "text-white",
};

class swal {
  messageAlert(text) {
    Swal.fire({
      text: text,
      timer: 1500,
      showConfirmButton: false,
    });
  }

  messageErrorAlert(text) {
    Swal.fire({
      icon: "error",
      text: text,
      timer: 1500,
      heightAuto: false,
      showConfirmButton: false,
    });
  }

  deleteAlert(callback) {
    const option = {
      title: "삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
      // customClass: sweetAlertDelteCustomClass,
      heightAuto: false,
    };
    Swal.fire(option).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }

  updateRoleAlert(name, role, value, callback) {
    const option = {
      title: `${name}님의 ${role} 권한을 ${value ? "on" : "off"} 하시겠습니까?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "변경",
      cancelButtonText: "취소",
      customClass: sweetAlertDelteCustomClass,
    };
    Swal.fire(option).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }

  async deleteCompleteToast() {
    await toast.success("삭제되었습니다.", {
      timeout: 1500,
    });
  }

  async editCompleteToast() {
    await toast.success("수정되었습니다.", {
      timeout: 1500,
    });
  }

  async createCompleteToast(title = "생성되었습니다.") {
    await toast.success(title, {
      timeout: 1500,
    });
  }

  async memberRoleUpdateComplete(userName, role) {
    await toast.success(`${userName}님의 ${role}권한이 변경되었습니다.`, {
      timeout: 2000,
    });
  }

  async successToast(message) {
    await toast.success(message, {
      timeout: 2000,
    });
  }

  async errorToast(message) {
    await toast.error(message, {
      timeout: 2000,
    });
  }

  async warningToast(message) {
    await toast.warning(message, {
      timeout: 2000,
    });
  }
}

export default new swal();
